<template>
  <div :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <div>
      <switchProfilePin
        v-if="showSwitchProfilePin"
        :profile="selectedProfile"
        @closeProfilePinPopup="closeProfilePinPopupEvent"
        :closePopupEvent="closeProfilePinPopupEvent"
        :isAddProfile="isAddProfile"
        @switchSetPin="switchSetPin"
      />
    </div>

    <div>
      <SetNewPin
        v-if="showNewPin"
        :profile="selectedProfile"
        @closeSetNewPinPopup="switchSetPin"
        :isAddProfile="isAddProfile"
      />
    </div>

    <div>
      <SetOrVerifyProfilePin
        v-if="showSetOrVerifyProfilePin"
        :profile="selectedProfile"
        :isSetNewPin="isSetNewPin"
        @closeProfilePinPopup="closeSetOrVerifyProfilePin"
        :closePopupEvent="closeSetOrVerifyProfilePin"
      />
    </div>

    <div class="switchProfileContainer">
      <div v-if="isLoading">
        <Loading></Loading>
      </div>
      <div v-if="!isLoading">
        <p class="whosWatching">{{ $t("Who's watching") }}</p>
      </div>
      <div v-if="!isLoading" class="switchProfileCard">
        <!-- Profiles -->
        <div
          v-for="profile in profilesList"
          @click="selectProfile(profile)"
          :key="profile.profileid"
          class="user-profile"
        >
          <img v-if="!profile.picture" src="@/assets/icons/ProfileDp.png" class="userImage" alt="" />
          <img v-if="profile.picture" :src="profile.picture" class="userImage" alt="" />
          <p class="userName">{{ profile.profilename }}</p>
          <p v-if="profile.profileid === subscriberid" class="userAsAdmin">{{ $t("admin") }}</p>
          <p v-if="profile.kidsmode === 'YES'" class="userLable">{{ $t("kids") }}</p>
        </div>

        <!-- Add new Profile -->
        <div v-if="showAddProfileButton" class="user-profile">
          <img src="@/assets/icons/add.png" class="userImage" alt="" @click="goToAddProfile" />
          <p class="userName">{{ $t("add profile") }}</p>
        </div>
      </div>

      <div class="loader-margin" v-if="isProfileLoading"><Loading /></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import snackbar from "node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.css";
import { showSnackBar } from '../../utilities';

export default {
  data() {
    return {
      profilesList: null,
      isLoading: false,
      localDisplayLang: null,
      showSwitchProfilePin: false,
      showNewPin: false,
      selectedProfile: null,
      isProfileLoading: false,
      showSetOrVerifyProfilePin: false,
      isSetNewPin: false,
      subscriberProfileDetails: null,
      noOfUserProfilesLimit: 4,
      isAddProfile: false,
			currentProfileIdAdminCheck : null
    };
  },

  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/overlayLoader.vue"),
    switchProfilePin: () =>
      import(/* webpackChunkName: "switchProfilePin" */ "@/components/Popups/ProfilePopups/switchProfilePin.vue"),
    SetOrVerifyProfilePin: () =>
      import(/* webpackChunkName: "switchProfilePin" */ "@/components/Profile/SetOrVerifyProfilePin.vue"),
    SetNewPin: () =>
      import(/* webpackChunkName: "SetNewPin" */ "@/components/Profile/SetNewPin.vue"),
  },

  computed: {
    ...mapGetters(["subscriberid", "profileid", "appConfig", "getPlanDeeplinkPath"]),

    showAddProfileButton() {
      if (
        this.subscriberid === this.profileid &&
        this.profilesList &&
        this.profilesList.length < this.noOfUserProfilesLimit
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    if (this.appConfig && this.appConfig.defaultProfileLimit) {
      this.noOfUserProfilesLimit = this.appConfig.defaultProfileLimit;
    }

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

    if (this.subscriberProfileDetails) {
      this.subscriberProfileDetails = JSON.parse(this.subscriberProfileDetails);
    }
  },

  mounted() {
    this.isLoading = true;
    this.listProfiles()
      .then((response) => {
        if(response.data) {
          this.profilesList = response.data.data;
          this.isLoading = false;
        }
      })
      .catch((err) => {
        this.isLoading = false;
      });
  },

  methods: {
    ...mapActions(["listProfiles", "switchProfile", "fcmMessagingUnsubscribeToTopic", "fcmMessagingSubscribeToTopic"]),

    ...mapMutations(["setProfileid"]),

    goToAddProfile() {
      if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilepin === "ENABLED") {
        console.log("Block 1");
        // If the user is in master profile only then the ADD PROFILE button will be shown. Hence no need to ask for PIN
        // this.selectedProfile = this.subscriberProfileDetails;
        // this.showSetOrVerifyProfilePin = true;
        
        this.$router.push({ name: "addProfile" });

        // this.isAddProfile = true;
      } else if (this.subscriberProfileDetails && this.subscriberProfileDetails.profilepin !== "ENABLED") {
        console.log("Block 2");

        this.selectedProfile = this.subscriberProfileDetails;
        this.isSetNewPin = true;
        this.showSetOrVerifyProfilePin = true;
      }
    },

    closeProfilePinPopupEvent() {
      this.showSwitchProfilePin = !this.showSwitchProfilePin;
    },

    switchSetPin() {
      console.log('show forgot pin screen');
      this.showNewPin = !this.showNewPin;
    },

    closeSetOrVerifyProfilePin() {
      this.showSetOrVerifyProfilePin = !this.showSetOrVerifyProfilePin;
    },

    selectProfile(profile) {
      this.isProfileLoading = true;
			this.currentProfileIdAdminCheck = localStorage.getItem("currentProfileIdAdminCheck");
			if(profile.profileid == this.currentProfileIdAdminCheck){
			// if (sessionStorage.getItem("setPlanDeeplinkPath")) {
			// 		this.$router.push(sessionStorage.getItem("setPlanDeeplinkPath"));
			// 		sessionStorage.removeItem("setPlanDeeplinkPath")
      //     //sessionStorage.removeItem("voucherCode");
			// 	} else {
					this.$router.push({ name: "Home" });
				//}
			//  if (this.getPlanDeeplinkPath) {
			//           this.$router.push(this.getPlanDeeplinkPath);
			//         } else {
			//            this.$router.push({ name: "Home" });
			//         }
			return;
			}
      if (profile.profilepin === "ENABLED") {
        this.selectedProfile = profile;
        this.isProfileLoading = false;
        this.showSwitchProfilePin = true;
      } else {
        const profilePayload = {
          profileid: profile.profileid,
        };

        if(profile.kidsmode === "YES") {
          this.unSubscribeToNotification();
        } else {  
          this.subscribeToTopic();
        }

        this.switchProfile(profilePayload)
          .then((response) => {
            if (!response.data.reason) {
              localStorage.setItem("sessionToken", response.data.success);
              this.isProfileLoading = false;

              localStorage.setItem("currentProfileId", profilePayload.profileid);
							localStorage.setItem("currentProfileIdAdminCheck", profilePayload.profileid);
              localStorage.setItem("subscriberProfileDetails", JSON.stringify(profile));

              this.setProfileid(profilePayload.profileid);
							//this.$router.push("/");
            //  if (sessionStorage.getItem("setPlanDeeplinkPath")) {
            //     this.$router.push(sessionStorage.getItem("setPlanDeeplinkPath"));
						// 		sessionStorage.removeItem("setPlanDeeplinkPath")
            //     //sessionStorage.removeItem("voucherCode");
            //   } else {
                this.$router.push("/");
             // }

               // this.$router.push("/");
              // }

            } else {
              showSnackBar(this.$t(response.reason));
              this.isProfileLoading = false;
            }
          })
          .catch((err) => {
            this.isProfileLoading = false;
          });
      }
    },

    unSubscribeToNotification() {
      this.fcmMessagingUnsubscribeToTopic(this.subscriberid);
    },

    subscribeToTopic() {
      this.fcmMessagingSubscribeToTopic(this.subscriberid);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.loader-margin {
  margin: 2rem 0 0 0;
}

.switchProfileContainer {
  // position: absolute;
  // top: 20%;
  margin-top: 7rem;
  width: 95vw;
  height: 75vh !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .whosWatching {
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: $font-regular;
    text-align: center;
  }
  .switchProfileCard {
    width: 60vw;
    margin-top: 3rem;
    display: flex;
    //flex-wrap: wrap;
    justify-content: space-around;
    .user-profile {
      display: flex;
      align-items: center;
      flex-direction: column;

      cursor: pointer;

      .userImage {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      .userName {
        margin-top: 1rem;
        opacity: 1;
        font-size: 1.2rem;
        font-weight: 600;
        font-family: $font-regular;
        color: $clr-dark-gd10;
      }
      .userAsAdmin {
        margin-top: 1rem;
        opacity: 1;
        font-size: 0.9rem;
        font-weight: 600;
        font-family: $font-regular;
        background: $btn-clr-new;
        padding: 5px;
        text-align: center;
        color: #ffffff;
        border-radius: 4px;
      }
      .userLable {
        margin-top: 1rem;
        opacity: 1;
        font-size: 0.9rem;
        font-weight: 600;
        font-family: $font-regular;
        background-color: #fcc10f;
        padding: 5px;
        text-align: center;
        color: black;
        border-radius: 4px;
      }
      .addProfileImage {
        cursor: pointer;
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
}

@media only screen and (min-width: 300px) and (max-width: 576px) {
  .switchProfileContainer {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 85vh !important;
    .whosWatching {
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      font-size: 1.2rem;
      font-weight: 600;
      font-family: $font-regular;
      text-align: center;
    }
    .switchProfileCard {
      width: 360px;
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2rem;
      // flex-wrap: wrap;
      .user-profile {
        margin-top: 1.2rem;
        // margin-right: 1rem;
        display: flex;
        margin-left: 0rem;
        // margin-right: 1rem;

        //  flex-direction: column;
        .userImage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        .userName {
          margin-top: 0.8rem;
          opacity: 1;
          font-size: 1rem;
          font-weight: 600;
          font-family: $font-regular;
          color: $clr-dark-gd10;
        }
        .userLable {
          margin-top: 1rem;
          opacity: 1;
          font-size: 0.9rem;
          font-weight: 600;
          font-family: $font-regular;
          background-color: #fcc10f;
          padding: 5px;
          text-align: center;
          color: black;
          border-radius: 4px;
        }
        .addProfileImage {
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          // padding-bottom: 4rem;
          // margin-left:4rem;
        }
        //  .userNameAddProfile{
        //    margin-left: 2rem;
        //    margin-top: 0.8rem ;
        //    opacity: 1;
        //   font-size: 1rem;
        //   font-weight: 600;
        //   font-family: $font-regular;
        //   color: $clr-dark-gd10;
        //  }
      }
      //justify-content: space-between;
    }
  }
}
</style>
